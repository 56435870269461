import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CommaConvertPipe } from '../../../../../../pipes/comma-convert.pipe';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MultiTranslateHttpLoader } from '../../../../../../classes/multi-translate-http-loader';

/**
 * CONTROLLA FILE classes/MultiTranslateHttpLoader
 * @param http
 * @returns
 */
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  // Specifica più percorsi per i file di traduzione
  return new MultiTranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    CommaConvertPipe
  ],
  imports: [
    NgxMaterialTimepickerModule.setOpts('it-IT'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'it',
      extend: true
    })
  ],
  exports: [
    TranslateModule,
    NgxMaterialTimepickerModule,
    CommaConvertPipe
  ]
})
export class SharedLavorazioniModule { }
