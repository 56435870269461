import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { forkJoin, map, Observable } from "rxjs";

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, private prefix: string = './assets/i18n/', private suffix: string = '.json') {}

  getTranslation(lang: string): Observable<any> {
      // Definisci i file di traduzione da caricare per ciascuna lingua
      const paths = [
          `${this.prefix}${lang}/rdi${this.suffix}`,
          `${this.prefix}${lang}/system${this.suffix}`,
          `${this.prefix}${lang}/barcode${this.suffix}`,
      ];

      // Usa forkJoin per caricare tutti i file e combinarli
      const requests = paths.map(path => this.http.get(path));

      return forkJoin(requests).pipe(
          map(responseList => {
              // Combina le risposte in un unico oggetto di traduzione
              return responseList.reduce((acc, translations) => ({ ...acc, ...translations }), {});
          })
      );
  }
}
